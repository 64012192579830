import ACTION, { USERS_STATE } from './creator'

const init = {
  users: {},
  status: USERS_STATE.PENDING,
  message: '',
}

export const users = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.USERS.PENDING:
    case ACTION.USERS.SUCCESS:
    case ACTION.USERS.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}

// export const updateUsers = (state = init, { type, payload }) => {
//   switch (type) {
//     case ACTION.USERS.PENDING:
//     case ACTION.USERS.SUCCESS:
//     case ACTION.USERS.UPDATE:
//       return { ...state, ...payload }
//     default:
//       return state
//   }
// }

// export default (GetUsers, UpdateUsers)
// export default GetUsers
