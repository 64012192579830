const REPORT_ACTIONS = {
  REPORT: {
    PENDING: 'REPORT_PENDING',
    SUCCESS: 'REPORT_SUCCESS',
    FAILURE: 'REPORT_FAILURE',
    UPDATE: 'REPORT_UPDATE',
  },
}

export const REPORT_STATE = {
  REFRESHED: 'REPORT_REFRESHED',
  IN_PROGRESS: 'LOADING_PROGRESS',
  SUCCESS: 'REPORT_RETRIEVED',
  ERROR: 'REPORT_ERROR',
}

export default REPORT_ACTIONS
