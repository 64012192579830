import ACTION, { PROFILE_STATE } from './creator'

const init = {
  profileData: {},
  status: PROFILE_STATE.PENDING,
  message: '',
}

export const profileData = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.PROFILE.PENDING:
    case ACTION.PROFILE.SUCCESS:
    case ACTION.PROFILE.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
