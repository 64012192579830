import ACTION, { REPORT_STATE } from './creator'

const init = {
  report: {},
  status: REPORT_STATE.PENDING,
  message: '',
}

export const report = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.REPORT.PENDING:
    case ACTION.REPORT.SUCCESS:
    case ACTION.REPORT.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
