import ACTION, { ACTIVITYLOG_STATE } from './creator'

const init = {
  activityLog: {},
  status: ACTIVITYLOG_STATE.PENDING,
  message: '',
}

export const activityLog = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.ACTIVITYLOG.PENDING:
    case ACTION.ACTIVITYLOG.SUCCESS:
    case ACTION.ACTIVITYLOG.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
