const PROFILE_ACTIONS = {
  PROFILE: {
    PENDING: 'PROFILE_PENDING',
    SUCCESS: 'PROFILE_SUCCESS',
    FAILURE: 'PROFILE_FAILURE',
    UPDATE: 'PROFILE_UPDATE',
  },
}

export const PROFILE_STATE = {
  REFRESHED: 'PROFILE_REFRESHED',
  IN_PROGRESS: 'PROFILE_PROGRESS',
  SUCCESS: 'PROFILE_RETRIEVED',
  ERROR: 'PROFILE_ERROR',
}

export default PROFILE_ACTIONS
