import ACTION, { LOCATION_STATE } from './creator'

const init = {
  location: {},
  status: LOCATION_STATE.PENDING,
  message: '',
}

export const location = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.LOCATION.PENDING:
    case ACTION.LOCATION.SUCCESS:
    case ACTION.LOCATION.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
