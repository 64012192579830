const ACTIVITYLOG_ACTIONS = {
  ACTIVITYLOG: {
    PENDING: 'ACTIVITYLOG_PENDING',
    SUCCESS: 'ACTIVITYLOG_SUCCESS',
    FAILURE: 'ACTIVITYLOG_FAILURE',
    UPDATE: 'ACTIVITYLOG_UPDATE',
  },
}

export const ACTIVITYLOG_STATE = {
  REFRESHED: 'ACTIVITYLOG_REFRESHED',
  IN_PROGRESS: 'LOADING_PROGRESS',
  SUCCESS: 'ACTIVITYLOG_RETRIEVED',
  ERROR: 'ACTIVITYLOG_ERROR',
}

export default ACTIVITYLOG_ACTIONS
