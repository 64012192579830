const SENSOR_ACTIONS = {
  SENSOR: {
    PENDING: 'SENSOR_PENDING',
    SUCCESS: 'SENSOR_SUCCESS',
    FAILURE: 'SENSOR_FAILURE',
    UPDATE: 'SENSOR_UPDATE',
  },
}

export const SENSOR_STATE = {
  REFRESHED: 'SENSOR_REFRESHED',
  IN_PROGRESS: 'LOADING_PROGRESS',
  SUCCESS: 'SENSOR_RETRIEVED',
  ERROR: 'SENSOR_ERROR',
}

export default SENSOR_ACTIONS
