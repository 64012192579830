import ACTION, { SENSORLOG_STATE } from './creator'

const init = {
  sensorLog: {},
  status: SENSORLOG_STATE.PENDING,
  message: '',
}

export const sensorLog = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.SENSORLOG.PENDING:
    case ACTION.SENSORLOG.SUCCESS:
    case ACTION.SENSORLOG.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
