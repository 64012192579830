const LOCATION_ACTIONS = {
  LOCATION: {
    PENDING: 'LOCATION_PENDING',
    SUCCESS: 'LOCATION_SUCCESS',
    FAILURE: 'LOCATION_FAILURE',
    UPDATE: 'LOCATION_UPDATE',
  },
}

export const LOCATION_STATE = {
  REFRESHED: 'LOCATION_REFRESHED',
  IN_PROGRESS: 'LOADING_PROGRESS',
  SUCCESS: 'LOCATION_RETRIEVED',
  ERROR: 'LOCATION_ERROR',
}

export default LOCATION_ACTIONS
