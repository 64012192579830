import ACTION, { SENSOR_STATE } from './creator'

const init = {
  sensor: {},
  status: SENSOR_STATE.PENDING,
  message: '',
}

export const sensor = (state = init, { type, payload }) => {
  switch (type) {
    case ACTION.SENSOR.PENDING:
    case ACTION.SENSOR.SUCCESS:
    case ACTION.SENSOR.FAILURE:
      return { ...state, ...payload }
    default:
      return state
  }
}
