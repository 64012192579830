import { combineReducers } from 'redux'

import layout from './layout/reducer'
import login from './login/reducer'
// import { dashboardData, countWidgetData } from './home/dashboard/reducer'
// import { visitorListData } from './home/visitor_list/reducer'
import { profileData } from './home/profile/reducer'
import { location } from './home/location/reducer'
import { sensor } from './home/sensor/reducer'
import { activityLog } from './home/activityLog/reducer'
import { users } from './home/users/reducer'
import { sensorLog } from './home/sensorLog/reducer'
import { report } from './home/report/reducer'

export default combineReducers({
  layout,
  login,
  profileData,
  location,
  sensor,
  activityLog,
  users,
  sensorLog,
  report,
})
