const USERS_ACTIONS = {
  USERS: {
    PENDING: 'USERS_PENDING',
    SUCCESS: 'USERS_SUCCESS',
    FAILURE: 'USERS_FAILURE',
    UPDATE: 'USERS_UPDATE',
  },
}

export const USERS_STATE = {
  REFRESHED: 'USERS_REFRESHED',
  IN_PROGRESS: 'LOADING_PROGRESS',
  SUCCESS: 'USERS_RETRIEVED',
  ERROR: 'USERS_ERROR',
}

export default USERS_ACTIONS
