const SENSORLOG_ACTIONS = {
  SENSORLOG: {
    PENDING: 'SENSORLOG_PENDING',
    SUCCESS: 'SENSORLOG_SUCCESS',
    FAILURE: 'SENSORLOG_FAILURE',
    UPDATE: 'SENSORLOG_UPDATE',
  },
}

export const SENSORLOG_STATE = {
  REFRESHED: 'SENSORLOG_REFRESHED',
  IN_PROGRESS: 'LOADING_PROGRESS',
  SUCCESS: 'SENSORLOG_RETRIEVED',
  ERROR: 'SENSORLOG_ERROR',
}

export default SENSORLOG_ACTIONS
